import { combineReducers } from 'redux';
import { auth } from './Auth/Auth.Reducer'
import { productReducer } from './Product/Product.Reducer';
import { categoryReducer } from './Category/Category.Reducer';
import { appReducer } from './common/App.Reducer';
import { cartReducer } from './Cart/Cart.Reducer';
import { orderReducer } from './order/Order.Reducer';
import { userReducer } from './User/User.Reducer';
const rootReducer = combineReducers({
    auth: auth,
    product: productReducer,
    category: categoryReducer,
    app: appReducer,
    cart: cartReducer,
    orderStore: orderReducer,
    userStore: userReducer
});
export default rootReducer;
