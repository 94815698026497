import { ActionType, IAppAction } from '../../actions/Helpers';
import IProduct from '../../../models/product/IProduct';

const initialState = {
    adminProductsList: [] as IProduct[],
    productsList: [] as IProduct[],
    selectedProductItem: {} as IProduct,
    editProductDetails: {} as IProduct,
    savedProductDetails:{} as IProduct,
    productListByCategoryId: [] as IProduct[],
}

export const productReducer = (state = initialState, action = {} as IAppAction) => {
    switch (action.type) {
        case ActionType.GET_PRODUCT_LIST:
            return { ...state, adminProductsList: action.payload }
        case ActionType.GET_PRODUCTS_LIST_SUCCESS:
            return { ...state, productsList: action.payload }
        case ActionType.GET_PRODUCTS_LIST_FAILURE:
            return { ...state, productsList: [] }
        case ActionType.GET_PRODUCT_BY_ID_SUCCESS:
            return { ...state, selectedProductItem: action.payload }
        case ActionType.GET_PRODUCT_BY_ID_FAILURE:
            return { ...state, selectedProductItem: action.payload }
        case ActionType.REMOVE_SELECTED_PRODUCT_FORM_STORE:
            return { ...state, selectedProductItem: action.payload }
        case ActionType.EDIT_PRODUCT_DETAILS:
            return { ...state, editProductDetails: action.payload }
        case ActionType.GET_PRODUCT_BY_CATEGORY_ID_SUCCESS:
            return { ...state, productListByCategoryId: action.payload }
        case ActionType.GET_PRODUCT_BY_CATEGORY_ID_FAILURE:
            return { ...state, productListByCategoryId: [] }
            case ActionType.SAVED_PRODUCT_DETAILS:
            return { ...state, savedProductDetails: action.payload }
        default:
            return state
    }
}
