import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { NavLink, Redirect, Route } from 'react-router-dom';
import { RouteAccess } from '../AppConstants';
import SideNavOptions from '../configs/SideNavOptions';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, Badge, IconButton, Popover, Grid, Container, Menu, MenuItem } from '@mui/material';
import { formatMoney } from "../pipes/PriceFormatter";
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { cartPopWindowOpen, cartPopWindowClose, getLoginUserDetailsByToken } from '../store/actions/App.Action';
import { getShoppingCartDetails, deleteCartItemById } from '../store/actions/Cart.Action'
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from "react-toastify";
const classNames = require('classnames');
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    position: 'relative',
    background: theme.palette.primary.main,
    top: 0,
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })

  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
    },
  },
  paper: {
    marginTop: '45px',
    marginLeft: '13px',
    border: '1px solid rgba(0, 0, 0, 0.26)'
  },
  popupMainContainer: {
    minWidth: '400px',
    maxWidth: '400px',
  },
  paddingZero: {
    padding: '0 !important',
    margin: 'auto !important',
    width: '100%',
  },
  paddingChargeDetails: {
    padding: '10px !important',
    margin: 'auto !important',
    width: '100%',
  },
  cartEmpty: {
    height: '45vh',
    background: "url('/static/images/shopping-cart.png') no-repeat center center",
    color: '#be9453',
    overflow: 'hidden'
  },
  popupHeader: {
    fontWeight: 600,
    color: '#f55089',
    marginLeft: '5px'
  },
  cartItems: {
    padding: 10,
    alignItems: 'center',
    paddingRight: 25
  },
  cartItemButton: {
    padding: 0,
  },
  deleteButton: {
    padding: 0,
  },
  costContainer: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '0',
    paddingBottom: '0'
  },
  subTotal: {

  },
  popupHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '34px',
    height: '6vh',
  },
  productToCheckoutContainer: {
    margin: '10px !important',
    textAlign: 'center'
  },
  productDetailsItem: {
    maxHeight: '300px',
    overflowY: 'scroll',
  },
  proceedToCheckout: {
    color: "white !important",
    width: '60%',
    borderRadius: '50px !important',
    backgroundColor: '#f55089 !important',
    '&:hover': {
      backgroundColor: '#f55089 !important',
    }
  },
  fillSpace: {
    flex: '1 1 auto'
  }
}));
const SideMiniDrawer = (props: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isLoading, isAuthenticated } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const locationActive = window.location.pathname;
  const { isCartPopWindowOpen } = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const openid = isCartPopWindowOpen ? 'simple-popover' : undefined;
  const { logout, routes, history } = props;
  const { shoppingCart, serviceCharge, taxTotal, total, cartCount } = useSelector((state: any) => state.cart);
  const { user } = useSelector((state: any) => state.auth);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (isAuthenticated && !isLoading && !user && count <= 4) {
      dispatch(getLoginUserDetailsByToken())
      setCount(count + 1)
    }
  }, [dispatch, user, isAuthenticated, isLoading, count]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading && !user) {
      dispatch(getLoginUserDetailsByToken())
    }
  }, [dispatch, user, isAuthenticated, isLoading]);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logoutAuth0 = () => {
    setAnchorElMenu(null);
    setAnchorEl(null);
    dispatch(cartPopWindowClose());
    logout();
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  };
  const removeItem = (cartItem: any) => {
    dispatch(deleteCartItemById(cartItem.itemId));
  }

  const handleClose = () => {
    dispatch(cartPopWindowClose());
    setAnchorEl(null)
  }

  const productToCheckout = () => {
    dispatch(cartPopWindowClose());
    history.push('/checkout')
  }
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    dispatch(cartPopWindowOpen());
  };


  useEffect(() => {
    if (!shoppingCart && isAuthenticated && !isLoading && (user && user?.userType === 'U')) {
      dispatch(getShoppingCartDetails())
    }
  }, [dispatch, shoppingCart, user, isAuthenticated, isLoading]);


  const handleClickOpenMenu = (event: any) => {
    setAnchorElMenu(event.currentTarget)
  };

  const gotoProfile = () => {
    setAnchorElMenu(null)
    history.push(`/profile`);
  }


  useEffect(() => {
    if (!localStorage.getItem('userType')) {
      if (isAuthenticated && !isLoading && user) {
        localStorage.setItem("userType", user.userType)
      }
    }
    if (user && user.userType !== localStorage.getItem('userType')) {
      localStorage.setItem("userType", user.userType)
    }
  }, [isAuthenticated, isLoading, user]);

  const gotoMyOrderListPage = () => {
    setAnchorElMenu(null)
    history.push(`/order-history`);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.fillSpace} variant="h5" color="inherit" noWrap={true}>
            <img alt="icon-logo" src="unwired_icon.png" height="30px" /> <img alt="icon-logo2" src="unwired_logo.png" width="150px" />
          </Typography>
          {(user && user?.userType === 'U') && <IconButton aria-label="show shopping cart" color="inherit" id="openModel" onClick={(event: any) => handleClick(event)}>
            <Badge badgeContent={cartCount} color="secondary">
              <ShoppingCartIcon fontSize="inherit" />
            </Badge>
          </IconButton>}
          {(user && user?.userType !== 'U') && <Typography variant='body2'>Welcome, {user?.firstname}</Typography>}
          <IconButton aria-label="show profile" color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClickOpenMenu(event)} >
            <AccountCircle />
          </IconButton>
          <Menu style={{ top: '8px' }}
            id="simple-menu-open"
            anchorEl={anchorElMenu}
            keepMounted={true}
            open={Boolean(anchorElMenu)}
            onClose={() => setAnchorElMenu(null)}
          >
            <MenuItem onClick={() => gotoProfile()}>Profile</MenuItem>
            <MenuItem onClick={() => logoutAuth0()}>Logout</MenuItem>
            {(user && user?.userType === 'U') && <MenuItem onClick={() => gotoMyOrderListPage()}>My Orders</MenuItem>}
            {(user && (user?.userType === 'C' || user?.userType === 'AU')) && <MenuItem onClick={() => gotoMyOrderListPage()}>Orders</MenuItem>}
          </Menu>
          <Popover
            id={openid}
            open={isCartPopWindowOpen}
            // anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{ paper: classes.paper }}
          >
            <Grid container={true} className={classes.popupMainContainer}>
              <Grid item={true} xs={12} sm={12} className={classes.paddingZero}>
                <Container component="main" disableGutters={true} maxWidth="md" >
                  <Grid container={true} spacing={2} className={classes.paddingZero}>
                    <Grid item={true} xs={12} sm={12} className={classNames(classes.paddingZero)}>
                      <Grid container={true} spacing={2} className={classes.paddingZero}>
                        <Grid item={true} xs={12} sm={12} className={classes.paddingZero} >
                          {shoppingCart?.items?.length > 0 && <div>
                            <div className={classes.popupHeaderContainer}>
                              <Checkbox checked={true} className={classes.popupHeader} />
                              <Typography className={classes.popupHeader} component="p">Item added</Typography>
                            </div>
                            <Divider />
                          </div>}
                          <Grid container={true} className={classNames(classes.productDetailsItem, (shoppingCart?.items === undefined || shoppingCart?.items?.length <= 0) && classes.cartEmpty)} style={{ textTransform: 'uppercase', overflow: 'scroll', overflowY: 'scroll' }}>
                            {(Array.isArray(shoppingCart?.items) && shoppingCart?.items?.length > 0) ?
                              shoppingCart?.items?.map((cartItem: any) => {
                                const { price, quantity, itemId, product } = cartItem;
                                return (
                                  <Grid item={true} xs={12} sm={12} key={itemId} className={classes.paddingZero}>
                                    <Grid container={true} className={classes.cartItems}>
                                      <Grid item={true} xs={12} sm={1} >
                                        <Typography variant='body2' >{quantity}</Typography>
                                      </Grid>
                                      <Grid item={true} xs={12} sm={7}>
                                        <Button className={classes.cartItemButton}>
                                          <Typography variant='body2'  >{product?.name}</Typography>
                                          {/* onClick={() => this.backToProductEdit(orderItem)} */}
                                        </Button>
                                      </Grid>
                                      <Grid item={true} xs={12} sm={2}>
                                        <Button className={classes.deleteButton}>
                                          <DeleteIcon style={{ fontSize: '24px', paddingTop: '2px' }} color='error' onClick={() => removeItem(cartItem)} />
                                        </Button>
                                      </Grid>
                                      <Grid item={true} xs={12} sm={2}>
                                        <Typography variant='body2' >$ {formatMoney(price)}</Typography>
                                      </Grid>
                                    </Grid>
                                    <Divider />
                                  </Grid>
                                )
                              }) :
                              <Grid container={true} justifyContent="center">
                                <Grid item={true} xs={12} sm={6} className={classes.paddingZero}>
                                  <Typography variant="body2" align="center" gutterBottom={true}
                                    style={{ position: 'sticky', color: '#cacaca', transform: 'translateY(61px)' }}
                                  >Your bag is empty.</Typography>
                                </Grid>
                              </Grid>
                            }
                          </Grid>
                        </Grid>
                        {(shoppingCart?.items?.length > 0) &&
                          <Grid item={true} xs={12} sm={12} className={classes.paddingChargeDetails}>
                            <Grid container={true} className={classes.costContainer}>
                              <Grid item={true} xs={12} sm={10} className={classes.paddingZero} >
                                <Typography className={classes.subTotal}>Service fee:</Typography>
                              </Grid>
                              <Grid item={true} xs={12} sm={2} className={classes.paddingZero} >
                                <Typography className={classes.subTotal}>$ {serviceCharge}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container={true} className={classes.costContainer}>
                              <Grid item={true} xs={12} sm={10} className={classes.paddingZero} >
                                <Typography className={classes.subTotal}>Tax:</Typography>
                              </Grid>
                              <Grid item={true} xs={12} sm={2} className={classes.paddingZero} >
                                <Typography className={classes.subTotal}>$ {taxTotal}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container={true} className={classes.costContainer}>
                              <Grid item={true} xs={12} sm={10} className={classes.paddingZero} >
                                <Typography className={classes.subTotal}>Total:</Typography>
                              </Grid>
                              <Grid item={true} xs={12} sm={2} className={classes.paddingZero} >
                                <Typography className={classes.subTotal}>$ {total}</Typography>
                              </Grid>
                            </Grid>
                            <Divider />
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
                {(shoppingCart?.items?.length > 0) &&
                  <Grid item={true} xs={12} sm={12} className={classes.paddingZero}>
                    <Grid item={true} xs={12} sm={12} className={classes.productToCheckoutContainer}>
                      <Button className={classes.proceedToCheckout} onClick={() => productToCheckout()}>Proceed to Checkout</Button>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} classes={{
        paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {user && SideNavOptions.map((route: any, index: number) => {
          if ((route.access === user?.userType) ||
            ((Array.isArray(route.access) && (route.access?.indexOf(user?.userType) > -1)) === (RouteAccess.AdminAndContributor.indexOf(user?.userType) > -1 || RouteAccess.All.indexOf(user?.userType) > -1))) {
            return (
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                }}
                activeClassName="active"
                style={{ textDecoration: 'none', color: 'black' }}
                key={index} exact={true} to={route.path}>
                <ListItem button={true}>
                  <ListItemIcon>
                    {route.icon()}
                  </ListItemIcon>
                  <ListItemText primary={route.title} />
                </ListItem>
              </NavLink>
            );
          }
          return null;
        })}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 1, width: '90%' }}>
        <DrawerHeader />
        {(locationActive === process.env.PUBLIC_URL || locationActive === process.env.PUBLIC_URL + '/') && (
          <>
            {user && <Route path="/" component={() => (<Redirect to="/unwired-product" />)} />}
          </>
        )}
        <ToastContainer autoClose={5000} />
        {user && renderRoutes(routes?.filter((x: any) => (x.access === user?.userType) ||
          ((Array.isArray(x.access) && (x.access?.indexOf(user?.userType) > -1)) === (RouteAccess.All.indexOf(user?.userType) > -1))))}
        {props.children}
      </Box>
    </Box >
  );
}



export default SideMiniDrawer;
