import { Button, Grid, Hidden, InputBase, Theme, Menu, MenuItem } from "@mui/material"
import { withStyles, createStyles } from '@mui/styles';
import IFilterModel from "../../models/common/IFilterModel";
import SearchIcon from '@mui/icons-material/Search';
import { ExportDataMenus } from '../export-data-button/ExportDataMenus';
const styles = (theme: Theme) => createStyles({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        marginLeft: 0,
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid #ccc',
        [theme.breakpoints.between('lg', 'xl')]: {
            width: '46ch',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '35ch',
        },
        [theme.breakpoints.only('xs')]: {
            width: '17ch',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    inputRoot: {
        color: 'inherit',
        border: '1px solid #ccc',
    },

    mobileView: {
        marginTop: '5px',
        // [theme.breakpoints.only('xs')]: {
        //     paddingLeft: 22, 
        //     paddingRight: 22,
        // },
        [theme.breakpoints.between('sm', 'xl')]: {
            padding: 8
        },
    },
    mobileSearchBox: {
        [theme.breakpoints.only('xs')]: {
            paddingRight: '13px'
        },

    }

});
export const UnwiredSearch = withStyles(styles)((props: any) => {
    const { classes, anchorEl, handleClose, handleClickListItem, filterMenuOptions,
        selectedFilterMenuIndex, handleMenuItemClick, searchBoxValue, heandleSerachBox,
        onEnterKeyPress, selectedFilterMenu, handleCloseExportMenu, buttonAddFunction, buttonAddLabel,
        exportMenuOptions, handleClickExport, exportAnchorEl, selectedExportMenuIndex, handleDownloadMenuItemClick } = props;
    return (<Grid container={true} spacing={2} className={classes.mobileView} style={{ marginTop: 10, }}>
        <Grid container={true} style={{ margin: '5px' }}>
            <Grid item={true} xs={4} sm={2} style={{ paddingLeft: '27px' }}>
                <Button style={{ backgroundColor: '#f6f6f6', textAlign: "center", color: '#6d6c6c' }} onClick={(event) => handleClickListItem(event)}>Filter By</Button>
                <Menu
                    id="show-filter-menu"
                    anchorEl={anchorEl}
                    keepMounted={true}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    elevation={0}
                // getContentAnchorEl={null}
                >
                    {filterMenuOptions && filterMenuOptions.map((option: IFilterModel, index: number) => {
                        return (
                            <MenuItem
                                key={option.key}
                                disabled={index === selectedFilterMenuIndex}
                                selected={index === selectedFilterMenuIndex}
                                onClick={(event: any) => handleMenuItemClick(event, index, option)}
                            >
                                {option.label}
                            </MenuItem>
                        )
                    }
                    )}
                </Menu>
            </Grid>
            <Hidden only={['sm', 'xs']}>
                <Grid item={true} sm={2}>
                    <Button color="primary" style={{ backgroundColor: '#f6f6f6', color: '#6d6c6c' }} disabled={true}>[{selectedFilterMenu}]</Button>
                </Grid>
            </Hidden>
            <Hidden only={['xl', 'md', 'lg']}>
                <Grid item={true} xs={8} sm={5} className={classes.mobileSearchBox}>
                    <div className={classes.search} >
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            name="searchBoxValue"
                            id="searchBoxValue"
                            value={searchBoxValue}
                            classes={{ root: classes.inputRoot, input: classes.inputInput, }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={heandleSerachBox}
                            onKeyPress={onEnterKeyPress}
                        />
                    </div>
                </Grid>
            </Hidden>
            <Hidden only={['sm', 'xs']}>
                <Grid item={true} xs={8} sm={4}>
                    <div className={classes.search} >
                        {/* <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div> */}
                        <InputBase
                            placeholder="Search…"
                            name="searchBoxValue"
                            id="searchBoxValue"
                            value={searchBoxValue}
                            classes={{ root: classes.inputRoot, input: classes.inputInput, }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={heandleSerachBox}
                            onKeyPress={onEnterKeyPress}
                        />
                    </div>
                </Grid>
            </Hidden>

            <Hidden only={['xs']}>
                <Grid item={true} sm={4} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    {(buttonAddFunction && buttonAddLabel) &&
                        <Button color="primary" onClick={buttonAddFunction} style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c', marginRight: 10 }} >{buttonAddLabel}</Button>
                    }

                    <ExportDataMenus handleCloseExportMenu={handleCloseExportMenu} exportMenuOptions={exportMenuOptions}
                        handleClickExport={handleClickExport} exportAnchorEl={exportAnchorEl} selectedExportMenuIndex={selectedExportMenuIndex}
                        handleDownloadMenuItemClick={handleDownloadMenuItemClick} />

                </Grid>
            </Hidden>
        </Grid>
        <Hidden only={['sm', 'xl', 'md', 'lg']}>
            <Grid item={true} xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                {(buttonAddFunction && buttonAddLabel) &&
                    <Button color="primary" onClick={buttonAddFunction} style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c', marginRight: 10 }} >{buttonAddLabel}</Button>
                }
                <ExportDataMenus handleCloseExportMenu={handleCloseExportMenu} exportMenuOptions={exportMenuOptions}
                    handleClickExport={handleClickExport} exportAnchorEl={exportAnchorEl} selectedExportMenuIndex={selectedExportMenuIndex}
                    handleDownloadMenuItemClick={handleDownloadMenuItemClick} />

            </Grid>
        </Hidden>
    </Grid>)

});