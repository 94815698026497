

export const ApiVariables = {
    getOrdersByRange: {
        url: 'orders/order/range',
        method: 'get',
        baseURL: 'order',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: string) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: string) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + ((this.parameterName && this.parameterValue) ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    postSaveORUpdateOrders: {
        url: 'orders/order/',
        method: 'post',
        baseURL: 'order',
        get apiEndPoint() {
            return this.url;
        },
    }
}


export const cartApi = {
    postCreateCart: {
        url: 'cart/create/',
        method: 'post',
        baseURL: 'cart',
        get apiEndPoint() {
            return this.url;
        },
    },
    putUpdateCartById: {
        cartId: '',
        url: 'cart/',
        method: 'put',
        baseURL: 'cart',
        get apiEndPoint() {
            return this.url + this.cartId;
        },
    },
    deleteCartById: {
        cartId: '',
        url: 'cart/cart/',
        method: 'delete',
        baseURL: 'cart',
        get apiEndPoint() {
            return this.url + this.cartId;
        },
    },
    getCartDetails: {
        url: 'cart/details',
        method: 'get',
        baseURL: 'cart',
        get apiEndPoint() {
            return this.url;
        },
    }
}

export const cartItemApi = {
    postAddCartItem: {
        url: 'cart/create/',
        method: 'post',
        baseURL: 'cart',
        get apiEndPoint() {
            return this.url;
        },
    },
    putUpdateCartItemById: {
        cartId: '',
        url: 'cart/',
        method: 'put',
        baseURL: 'cart',
        get apiEndPoint() {
            return this.url + this.cartId;
        },
    },
    deleteCartItemById: {
        itemId: '',
        url: 'cart/item/',
        method: 'delete',
        baseURL: 'cart',
        get apiEndPoint() {
            return this.url + this.itemId;
        },
    },
}

export const orderEndPoint = {
    postMakeOrder: {
        url: 'orders/order/',
        cartId: '',
        method: 'post',
        baseURL: 'order',
        get apiEndPoint() {
            return this.url + '?cartId=' + this.cartId;
        },
    },
    getOrderDetailsById: {
        url: 'orders/order/',
        orderId: '',
        method: 'get',
        baseURL: 'order',
        get apiEndPoint() {
            return this.url + this.orderId;
        },
    },
    getOrderHistory: {
        url: 'orders/range?',
        orderId: '',
        method: 'get',
        baseURL: 'order',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: string) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: string) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + ((this.parameterName && this.parameterValue) ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },

}

export const SensorEndPoints = {
    getSensorsByRange: {
        url: 'sensors/range?',
        method: 'get',
        baseURL: 'sensor',
        start: 0,
        limit: 100,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: string) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: string) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + ((this.parameterValue && this.parameterName) ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
}

export const StmMucEndPoints = {
    getStmMcuFamilyByRange: {
        url: 'stmMcuFamily/range?',
        method: 'get',
        baseURL: 'stmMcu',
        start: 0,
        limit: 100,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: string) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: string) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + ((this.parameterValue && this.parameterName) ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
}

export const usersEndPoint = {
    putUpdateUserById: {
        userId: '',
        url: 'users/user/',
        method: 'put',
        baseURL: 'users',
        get apiEndPoint() {
            return this.url + this.userId;
        },
    },
    getUserById: {
        url: 'users/user/me',
        method: 'get',
        baseURL: 'users',
        get apiEndPoint() {
            return this.url;
        },
    },

    getGitUserName: {
        url: 'users/github/',
        method: 'get',
        baseURL: 'users',
        userName: '',
        get apiEndPoint() {
            return this.url + this.userName;
        },
    },

}

export const customersApi = {
    getCustomersByRange: {
        url: 'customers/range?',
        method: 'get',
        baseURL: 'customers',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: string) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: string) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    getCustomersByOwnerId: {
        id: '',
        url: 'owners/owner/',
        method: 'get',
        baseURL: 'customers',
        start: 0,
        limit: 10,
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        endUrl: '/customers/range?',
        get apiEndPoint() {
            return this.url + this.id + this.endUrl + 'start=' + this.start + '&limit=' + this.limit;
        },
    },
    getDashBoardDeviceCountDetails: {
        id: '',
        url: 'reports/owner/customer/device/count',
        method: 'get',
        baseURL: 'dashboard',
        endUrl: '?ownerId=',
        get apiEndPoint() {
            return this.url + (this.id ? this.endUrl + this.id : '');
        },
    },
    getDevicesByCustomerIdToShowTheMarkers: {
        customerId: '',
        url: '/customers/customer/',
        method: 'get',
        baseURL: 'customers',
        start: 0,
        limit: 50,
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        endUrl: '/devices?',
        get apiEndPoint() {
            return this.url + this.customerId + this.endUrl + 'start=' + this.start + '&limit=' + this.limit;
        },
    },
    putUpdateCustomerById: {
        customerId: '',
        url: 'customers/customer/',
        method: 'put',
        baseURL: 'customerSave',
        get apiEndPoint() {
            return this.url + this.customerId;
        },

    },
    postCustomer: {
        url: 'customers/customer/',
        method: 'post',
        baseURL: 'customerSave',
        get apiEndPoint() {
            return this.url;
        },

    }

}

export const categoryApi = {
    getCategoryByRange: {
        url: 'categorys/range?',
        method: 'get',
        baseURL: 'category',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: string) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: string) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    postSaveCategory: {
        url: 'categorys/category/',
        method: 'post',
        baseURL: 'category',
        get apiEndPoint() {
            return this.url;
        },
    },
    putUpdateCategoryById: {
        categoryId: '',
        url: 'categorys/category/',
        method: 'put',
        baseURL: 'category',
        get apiEndPoint() {
            return this.url + this.categoryId;
        },
    },
    getCategoryById: {
        categoryId: '',
        url: 'categorys/category/',
        method: 'get',
        baseURL: 'category',
        get apiEndPoint() {
            return this.url + this.categoryId;
        },
    },
}

export const productApi = {
    getProductsByRange: {
        url: 'products/range?',
        method: 'get',
        baseURL: 'product',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        userId: null,
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        set setUserId(userId: any) {
            this.userId = userId;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + ((this.parameterValue && this.parameterName) ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit + (this.userId ? `&userId=${this.userId}` : '');
        },
    },
    postSaveProduct: {
        url: 'products/product/',
        method: 'post',
        baseURL: 'product',
        get apiEndPoint() {
            return this.url;
        },
    },
    postSaveProductImages: {
        queryParam: '',
        url: 'product/images/upload?imageDto=',
        method: 'post',
        baseURL: 'product-image',
        get apiEndPoint() {
            return this.url + this.queryParam;
        },
    },
    putUpdateProductById: {
        productId: '',
        url: 'products/product/',
        method: 'put',
        baseURL: 'product',
        get apiEndPoint() {
            return this.url + this.productId;
        },
    },
    getProductById: {
        productId: '',
        url: 'products/product/',
        method: 'get',
        baseURL: 'product',
        get apiEndPoint() {
            return this.url + this.productId;
        },
    },
}