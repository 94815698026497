import { IAppAction, ActionType } from './Helpers';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables'
export const getAllCustomers = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.customersApi.getCustomersByRange.setLimit = 1000;
        api(ApiVariables.customersApi.getCustomersByRange).then((response: any) => {
            dispatch({ type: ActionType.SET_CUSTOMER_SELECT_BOX_VALUE, payload: response });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};


export const getAllCustomersByOwnerId = (ownerId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.customersApi.getCustomersByOwnerId.id = ownerId;
        ApiVariables.customersApi.getCustomersByOwnerId.setLimit = 1000;
        api(ApiVariables.customersApi.getCustomersByOwnerId).then((response: any) => {
            dispatch({ type: ActionType.SET_CUSTOMER_SELECT_BOX_VALUE, payload: response });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};

export const getAllCategory = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.categoryApi.getCategoryByRange.start = 0;
        ApiVariables.categoryApi.getCategoryByRange.setLimit = 1000;
        api(ApiVariables.categoryApi.getCategoryByRange).then((response: any) => {
            dispatch({ type: ActionType.SET_CATEGORY_SELECT_BOX_VALUE, payload: response.data });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};

export const getSensorsList = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.SensorEndPoints.getSensorsByRange.start = 0;
        ApiVariables.SensorEndPoints.getSensorsByRange.setLimit = 1000;
        api(ApiVariables.SensorEndPoints.getSensorsByRange).then((response: any) => {
            dispatch({ type: ActionType.GET_SENSOR_LIST, payload: response.data });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};

export const getStmMcuFamilyList = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.StmMucEndPoints.getStmMcuFamilyByRange.start = 0;
        ApiVariables.StmMucEndPoints.getStmMcuFamilyByRange.setLimit = 1000;
        api(ApiVariables.StmMucEndPoints.getStmMcuFamilyByRange).then((response: any) => {
            dispatch({ type: ActionType.GET_SMT_MCU_LIST, payload: response.data });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};