import { ActionType, IAppAction } from './Helpers';
import { Dispatch } from 'redux';
import { AppState } from '../../state/AppState';
import { Spinner } from '../../state/Spinner';
import Product from '../../models/product/IProduct';
import * as ApiVariables from '../../service/ApiVariables'
import { toast } from 'react-toastify';

export const postMakeOrder = (body: string,cartId:string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.orderEndPoint.postMakeOrder.cartId = cartId;
        api({ ...ApiVariables.orderEndPoint.postMakeOrder, body }).then((response: any) => {
            if (response.status.success === "SUCCESS") {
                toast(response.status.message, { type: 'success' });
              } else if (response.status.success === "FAILED") {
                toast(response.status.message, { type: 'error' });
              }
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((response: any) => {
            toast("Something went wrong", { type: 'error' })
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(response);
        })
    })
}
export const getOrderDetailsById = (orderId:string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.orderEndPoint.getOrderDetailsById.orderId = orderId;
        api(ApiVariables.orderEndPoint.getOrderDetailsById).then((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER });
            dispatch(fetchOrderDetailsByIdSuccess(response))
            resolve(response);
        }).catch((error: any) => {
            toast("Something went wrong", { type: 'error' });
            dispatch(fetchOrderDetailsByIdFailure(error))
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(error);
        })
    })
}

export function fetchOrderDetailsByIdSuccess(response: any) {
    return {
        type: ActionType.GET_ORDER_DETAILS_SUCCESS,
        payload: response.data,
    };
}

export function fetchOrderDetailsByIdFailure(error: any) {
    return {
        type: ActionType.GET_ORDER_DETAILS_FAILURE,
        payload: error,
    };
}

export const removeItemFromCart = (productId: number): IAppAction => {
    return {
        type: ActionType.REMOVE_ITEM_FROM_CART,
        payload: productId
    }
};
export const addNewProduct = (product: Product): IAppAction => {
    return {
        type: ActionType.ADD_PRODUCT,
        payload: product
    }
};
export const getMenuCategory = (): IAppAction => {
    return {
        type: ActionType.GET_MENU_LIST,
        payload: {}
    }
};
export const fetchOrderHistorySuccess = (response:any): IAppAction => {
    return { type: ActionType.GET_ORDERS_HISTORY_SUCCESS,payload:response }
};
export const fetchOrderHistoryFail = (error:any): IAppAction => {
    return { type: ActionType.GET_ORDERS_HISTORY_FAILURE,payload:error}
};


export const getOrderHistory = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
    return new Promise((resolve, reject) => {
        ApiVariables.orderEndPoint.getOrderHistory.start = 0;
        ApiVariables.orderEndPoint.getOrderHistory.limit = 100;
        api(ApiVariables.orderEndPoint.getOrderHistory).then((response: any) => {
            dispatch(fetchOrderHistorySuccess(response.data))
            dispatch({ type: ActionType.CLOSE_SPINNER });
            resolve(response);
        }).catch((error: any) => {
            toast("Something went wrong", { type: 'error' });
            dispatch(fetchOrderHistoryFail(error))
            dispatch({ type: ActionType.CLOSE_SPINNER });
            reject(error);
        })
    })
}
