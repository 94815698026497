
import ICategory from '../../models/category/ICategory';
import { ActionType, IAppAction } from './Helpers';

export const fetchCategoryListByRange = (start: number, limit: number, parameterName?: string, parameterValue?: string) => {
    const qryParam = `start=${start}&limit=${limit}` + (parameterName ? `&${parameterName}=${parameterValue}` : '');
    return { type: ActionType.GET_CATEGORY_LIST_REQUEST, payload: qryParam };
};

export const fetchCategoryListByRangeSuccess = (response: any) => {
    return { type: ActionType.GET_CATEGORY_LIST_SUCCESS, payload: response };
}

export const fetchCategoryListByRangeFailure = () => {
    return { type: ActionType.GET_CATEGORY_LIST_SUCCESS, payload: null };
}




export const postSaveCategory = (body: string) => {

}

export const putUpdateCategory = (categoryId: string, body: string) => {

}

export const getCategoryById = (categoryId: string) => {

};

export const resetEditCategoryDetails = (): IAppAction => {
    return { type: ActionType.EDIT_CATEGORY_DETAILS, payload: {} };
};
export const editCategoryDetails = (categoryDetails: ICategory): IAppAction => {
    return { type: ActionType.EDIT_OWNER_DETAILS, payload: categoryDetails };
};





