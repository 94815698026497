import React from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { formatMoney } from "../../pipes/PriceFormatter";
import { Grid, Button, Theme } from '@mui/material';
import { getOrderHistory } from '../../store/actions/Order.Action';
import OrderHistoryCard from '../../components/Order/OrderHistoryCard';

const classNames = require('classnames');


interface IPageProps {
    classes?: any;
    history?: any;
    match: any;
    orderHistory?: any;
    getOrderHistory: () => void;
}

interface IPageState {
    title?: string;
}

class OrderHistory extends React.Component<IPageProps, IPageState> {

    constructor(props: any) {
        super(props)
        this.state = {}
    }

    public UNSAFE_componentWillMount() {
        this.props.getOrderHistory();
    }

    private goShoppingPage = () => {
        this.props.history.push('/unwired-product');
    };
    public render() {
        const { classes, orderHistory } = this.props;
        if (!orderHistory && orderHistory?.length === 0) {
            return (<div>
                <Typography gutterBottom={true} className={classes.headingPrimary}>No orders found<br /></Typography>
                <Button color='primary' style={{
                    color: '#141515',
                    backgroundColor: '#bbdefb',
                    marginBottom: 10,
                }} onClick={this.goShoppingPage}>BACK TO SHOPPING</Button>
            </div>
            )
        }
        return (<div className={classes.root}>
            <Typography gutterBottom={true} align="center" variant="h6" component="p" className={classes.headingPrimary}>Your Order History</Typography>
            <Container component="main" disableGutters={false} maxWidth="lg" className={classNames(classes.mainContainer)} style={{ overflow: 'scroll', overflowY: 'scroll' }}>
                {Array.isArray(orderHistory) && orderHistory?.length > 0 ?
                    <Grid container={true} style={{ backgroundColor: 'transparent',display:'flex',justifyContent:'center' }}>
                        <Grid item={true} xs={12} sm={6} className={classes.productListReviewContainer} >
                            {orderHistory.map((orderItem:any,indexNumber:number)=>{
                                return(<OrderHistoryCard orderItem={orderItem}/>)
                            })}
                        </Grid>
                        <Grid item={true} xs={12} sm={12} style={{ textAlign: 'center', marginTop: 10 }}>
                            <Button color='primary' style={{
                                color: '#141515',
                                backgroundColor: '#bbdefb',
                                marginBottom: 10,
                            }} onClick={this.goShoppingPage}>BACK TO SHOPPING</Button>
                        </Grid>
                    </Grid>
                    : <Grid>
                        <Grid item={true} xs={12} sm={12} style={{ textAlign: 'center', marginTop: 10 }}>
                            <Button color='primary' style={{
                                color: '#141515',
                                backgroundColor: '#bbdefb',
                                marginBottom: 10,
                            }} onClick={this.goShoppingPage}>BACK TO SHOPPING</Button>
                        </Grid>

                    </Grid>}
            </Container>
        </div>);
    }
}

const styles = (theme: Theme) => ({
    root: {
        marginTop: '10px',
        marginBottom: '70px',
    },
    productListReviewContainer: {
        marginBottom: '15px',
       // backgroundColor: 'white',

    },
    productListReview: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: '5px',
    },
});
const mapStateToProps = (state: any) => ({
    orderHistory: state.orderStore?.orderHistoryList
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
        getOrderHistory: getOrderHistory
    }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(OrderHistory)) as React.ComponentType<any>);