export interface ISpinner {
    message?: string | null;
}


export class Spinner {
    public static MESSAGE = 'message';
    public message: string;

    constructor(data: string) {
        this.message = data;
    }
}