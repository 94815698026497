/* eslint-disable @typescript-eslint/no-array-constructor */
import { ActionType, IAppAction } from "../../actions/Helpers";

const initialState = {
    editUserDetails: Object(null),
    userProfileDetails: Object(null),
}
export const userReducer = (state = initialState, action: IAppAction) => {
    switch (action.type) {
        case ActionType.EDIT_USER_DETAILS:
            return { ...state, editUserDetails: action.payload };
        case ActionType.USER_PROFILE_DETAILS:
            return { ...state, userProfileDetails: action.payload.data };
        default:
            return state
    };
};
