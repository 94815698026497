import { ActionType } from './Helpers';

export function showLoader() {
  return {
    type: ActionType.OPEN_SPINNER
  };
}

export function hideLoader() {
  return {
    type: ActionType.CLOSE_SPINNER
  };
}

export const cartPopWindowOpen = () => {
  return {
    type: ActionType.ADD_CART_POP_WINDOW_OPEN,
  }
};

export const cartPopWindowClose = () => {
  return {
    type: ActionType.ADD_CART_POP_WINDOW_CLOSE,
  }
};


export const getLoginUserDetailsByToken = () => {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_REQUEST,
  }
};
export const getLoginUserDetailsByTokenSuccess = (response: any) => {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_SUCCESS,
    payload: response
  }
};
export const getLoginUserDetailsByTokenFail = (error: any) => {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_FAILURE,
    payload: error
  }
};

export const openProductDetailPageModel = () => {
  return {
    type: ActionType.OPEN_PRODUCT_DETAIL_PAGE_MODEL,
  }
};
export const closeProductDetailPageModel = () => {
  return {
    type: ActionType.CLOSE_PRODUCT_DETAIL_PAGE_MODEL,
  }
};

