import * as React from 'react';
import { withStyles } from '@mui/styles';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Theme, Input, Button, Grid, Typography, Paper, Container, TextareaAutosize, TextField, CardActions, CardHeader, Card, CardContent, Collapse, ButtonGroup, FormGroup } from '@mui/material';
import SimpleReactValidator from 'simple-react-validator';
import IProduct from '../../models/product/IProduct';
import * as ProductAction from '../../store/actions/Product.Action';
import * as CommonAction from '../../store/actions/Common.Action';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const classNames = require('classnames');

interface IPageProps {
    classes?: any,
    history: any;
    match: any;   
    savedProductDetails?: IProduct

}
interface IPageState {
    title?: string
   
}
class Instruction extends React.Component<RouteComponentProps & IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();

    constructor(props: any) {
        super(props);
        this.state = {}
    }

    public componentWillMount = async () => {
       

    }
    public componentWillUnmount() {
    }
   
    public componentDidUpdate(prevProps: IPageProps, prevState: IPageState) {
      
    }
    private handleNext = () => {
        this.props.history.push('/unwired-product');
    }
    public subHeader(){

        return (<div>
            <Grid container spacing={2} paddingBottom={2} paddingTop={2}>
                <Grid item xs={6} md={2}>
                    <ButtonGroup size="small" aria-label="small button group">
                        <Button>HTTPS</Button>
                        <Button>SSH</Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={6} md={10}>
    
                    <FormGroup row>
                        <TextField InputProps={{ className: this.props.classes.input }}id="url" variant="outlined" disabled value={this.props?.savedProductDetails?.cloneUrl} />
                        <Button className={this.props.classes.button} variant="contained" disableElevation  onClick={() => this.copyToClipboard()}>
                            <ContentCopyIcon fontSize={'small'} ></ContentCopyIcon>
                        </Button>
                    </FormGroup>  </Grid>
            </Grid>
           
            
           
            Get started by creating a new file or uploading an existing file.We recommend every repository include a README, LICENSE, and .gitignore.   <br/>
            
            </div>
     
        )
    }
    public copyToClipboard() {
        var textField =document.getElementById('url')
        navigator.clipboard.writeText(textField?.['value']);
    }
    public render() {
        const { classes,savedProductDetails } = this.props;

        return (
            <Paper className={classNames(classes.paper)} elevation={5} square={false}>
                <Typography variant="h5" align='center' className={classes.sectionTitle}>Instruction</Typography>
                <Grid container={true} spacing={4}>
                    <Container maxWidth="lg">
                        <Card>
                            <CardHeader 
                                className={classes.cardHeader}
                                title="Quick setup — if you’ve done this kind of thing before" 
                                subheader={this.subHeader()}
                            />

                            <CardContent>
                                <Typography  className={classes.cardTitle} variant='h5'>…or create a new repository on the command line</Typography>
                                <Card className={classes.subCardHeader}>
                                <Typography variant="body2" className={classes.cardBodyContent}>
                                    echo "# dummy"  README.md <br/>
                                    git init<br/>
                                    git add README.md<br/>
                                    git commit -m "first commit"<br/>
                                    git branch -M main<br/>
                                    git remote add origin <Link className='cardHeader' to={''}>{savedProductDetails?.cloneUrl}</Link><br/>
                                    git push -u origin main<br/>
                                </Typography>   
                                </Card>

                                <Typography className={classes.cardTitle} variant='h5'>…or create a new repository on the command line</Typography>
                                <Card className={classes.subCardHeader}>
                                <Typography variant="body2" className={classes.cardBodyContent}>
                                git remote add origin <Link to={''}>{savedProductDetails?.cloneUrl}</Link> <br/>
                                git branch -M main<br/>
                                git push -u origin main <br/>
                                </Typography>   
                                </Card>
                            </CardContent>
                            <CardActions>
                                <Button  onClick={this.handleNext}
                                            variant="text"
                                            color="primary"
                                            style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' }}
                                            className={classes.buttonCommon} size="small">Go Back To Product</Button>
                            </CardActions>
                        </Card>
                    </Container>
                </Grid>
            </Paper>)
    }
}
const styles = (theme: Theme) => ({
    input: {
        height: 30,
        width: '660px'
      },
      button: {
        height: 30
      },
    paper: {
        padding: theme.spacing(3),
        color: theme.palette.text.secondary,
        marginBottom: '6%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    formTitle: {
        fontWeight: 'bold'
    },
    buttonCommon: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
        [theme.breakpoints.between('md', 'xl')]: {
            width: '50%'
        },
    },
    alignButton: {
        marginBottom: '4px',
        [theme.breakpoints.between('md', 'xl')]: {
            flexDirection: 'row-reverse',
            textAlign: 'center',
        },
    },
    sectionTitle: {
        marginBottom: "32px !important"
    },
    textareaBoxSize: {
        minWidth: '100%',
        maxWidth: '100%',
        border: '2px solid #cccc'
    },
    cardBodyContent:{
        backgroundColor:'#f6f8fa',
        boxShadow:'none',
        marginBottom:'24px !important'
    },
    subCardHeader:{
        boxShadow:'none !important'
    },
    cardTitle:{
        paddingBottom:'10px',
        borderTopColor: 'lightgray',
        borderTopStyle: 'solid',
        borderTopWidth: '1px'
    },
    cardHeader:{
        backgroundColor:'aliceblue'
    }
});
const mapStateToProps = (state: any) => {
    return {
     
        savedProductDetails: state.product?.savedProductDetails,
      
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        // savedProductDetails: ProductAction.savedProductDetails,
        
    }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(Instruction as any)) as any);
