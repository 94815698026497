import { RouteAccess } from '../AppConstants';
import LoginButton from '../components/Auth/LoginButton';
import instruction from '../pages/instruction/instruction';
import OrderHistory from '../pages/orders/OrderHistory';
import Profile from '../pages/profile/Profile';
import ProductForm from '../pages/unwired-product/ProductForm';
import UnwiredContributorProductView from '../pages/unwired-product/UnwiredContributorProductView';
const routes = [
  {
    path: '/login',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
 
  {
    path: '/profile',
    component: Profile,
    exact: true,
    access: RouteAccess.All,
  },

  {
    path: '/unwired-product',
    component: UnwiredContributorProductView,
    exact: true,
    access: RouteAccess.Contributor,
  },
  {
    path: '/instruction',
    component: instruction,
    exact: true,
    access: RouteAccess.Contributor,
  },
  {
    path: '/unwired-product/add',
    component: ProductForm,
    exact: true,
    access: RouteAccess.AdminAndContributor,
  },
  {
    path: '/unwired-product/edit/:id',
    component: ProductForm,
    exact: true,
    access: RouteAccess.AdminAndContributor,
  },
  
  {
    path: '/order-history',
    component: OrderHistory,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '*',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
];

export default routes;
