import { TokenStoreKey } from '../AppConstants';
// import authService from './Auth.Service';
export const PORTAL_API_URL = process.env.REACT_APP_BASE_URL;

export const getHeader = async () => {
  //const accessToken = await authService.getToken();
  let headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (localStorage.getItem(TokenStoreKey)) {
    headers.Authorization = `Bearer ${localStorage.getItem(TokenStoreKey)}`;
  }
  return headers;
};
