import { AppState } from '@auth0/auth0-react';
import { Dispatch } from 'react';
import { ActionType, IAppAction } from './Helpers';
import { Spinner } from '../../state/Spinner';
import * as ApiVariables from '../../service/ApiVariables'
import { toast } from 'react-toastify';

export function fetchLoginUserDetails(token: string) {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_REQUEST,
    payload: token,
  };
}

export function fetchUserSuccess(user: any) {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_SUCCESS,
    payload: user,
  };
}

export function fetchUserFailure() {
  return {
    type: ActionType.GET_LOGIN_USER_DETAILS_FAILURE,
  };
}

export function putUserRequest(payload: any) {
  return {
    type: ActionType.USER_PUT_REQUEST,
    payload: payload,
  };
}

export function putUserSuccess(user: any) {
  return {
    type: ActionType.USER_PUT_SUCCESS,
    payload: user,
  };
}

export function putUserFailure() {
  return {
    type: ActionType.USER_PUT_FAILURE,
  };
}

export function fetchLoginUserSuccess(data: any): any {
  throw new Error('Function not implemented.');
}

export function fetchLoginUserFailure(): any {
  throw new Error('Function not implemented.');
}


export const getUserProfileById = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
  return new Promise((resolve, reject) => {
    api(ApiVariables.usersEndPoint.getUserById).then((response: any) => {
      dispatch({ type: ActionType.USER_PROFILE_DETAILS, payload: response });
      resolve(response);
      dispatch({ type: ActionType.CLOSE_SPINNER });
    }).catch((response: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });
      reject(response);
    })
  })
}

export const getGitUserIsAvailable = (gitUserName: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
  return new Promise((resolve, reject) => {
    ApiVariables.usersEndPoint.getGitUserName.userName = gitUserName
    api(ApiVariables.usersEndPoint.getGitUserName).then((response: any) => {
      resolve(response);
      dispatch({ type: ActionType.CLOSE_SPINNER });
    }).catch((response: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });
      reject(response);
    })
  })
}

export const resetProfileStore = (): IAppAction => {
  return { type: ActionType.USER_PROFILE_DETAILS, payload: {} };
};

export const putUpdateUserById = (userId: string, body: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
  dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner('') })
  return new Promise((resolve, reject) => {
    ApiVariables.usersEndPoint.putUpdateUserById.userId = userId;
    api({ ...ApiVariables.usersEndPoint.putUpdateUserById, body }).then((response: any) => {
      if (response.status.success === "SUCCESS") {
        toast(response.status.message, { type: 'success' });
      } else if (response.status.success === "FAILED") {
        toast(response.status.message, { type: 'error' });
      }
      dispatch({ type: ActionType.CLOSE_SPINNER });
      resolve(response);
    }).catch((response: any) => {
      dispatch({ type: ActionType.CLOSE_SPINNER });
      toast("Something went wrong", { type: toast.TYPE.ERROR });
      reject(response);
    })
  })
}
