import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

export default function OrderHistoryCard(props: any) {
  const theme = useTheme();

  const { address1,
    address2,
    city,
    country,
    email,
    firstName,
    grandTotal,
    itemDiscount,
    lastName,
    middleName,
    mobileNo,
    orderId,
    province,
    shipping,
    status,
    subTotal,
    tax,
    total,
    zipCode, } = props.orderItem;
  return (
    <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginBottom:2, }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', width: '100%' }}>
          <Typography component="div" variant="h5" >
            Order Id: # {orderId}
          </Typography>

          <Typography component="div" style={{ justifyContent: 'center' }}>
            <Typography variant="subtitle1" color="text.secondary" style={{ textAlign: 'center' }} component="div">
              Order Status: <b>{status}</b>
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              Total: <b> $ {total}</b>
            </Typography>

            <Typography variant="subtitle1" color="text.secondary" component="div">
              Tax: <b>$ {tax}</b>
            </Typography>

            <Typography variant="subtitle1" color="text.secondary" component="div">
              Sub Total: <b>$ {subTotal}</b>
            </Typography>
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}

OrderHistoryCard.propTypes = {
  orderItem: PropTypes.object.isRequired
};