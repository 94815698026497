import { ActionType, IAppAction } from '../../actions/Helpers';

const initialState = {
    isAppLoader: false as boolean,
    isCartPopWindowOpen: false as boolean,
    sensorsList: [] as any,
    stmMcuFamilyList: [] as any,
}

export const appReducer = (state = initialState, action = {} as IAppAction) => {
    switch (action.type) {
        case ActionType.OPEN_SPINNER:
            return { ...state, isAppLoader: true }
        case ActionType.CLOSE_SPINNER:
            return { ...state, isAppLoader: false }
        case ActionType.ADD_CART_POP_WINDOW_OPEN:
            return { ...state, isCartPopWindowOpen: true }
        case ActionType.ADD_CART_POP_WINDOW_CLOSE:
            return { ...state, isCartPopWindowOpen: false }
        case ActionType.GET_SENSOR_LIST:
            return { ...state, sensorsList: action.payload }
        case ActionType.GET_SMT_MCU_LIST:
            return { ...state, stmMcuFamilyList: action.payload }
        default:
            return state
    }
}
