import * as React from 'react';
import { withStyles } from '@mui/styles';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormControl, InputLabel, Theme, Input, Button, Grid, Typography, Paper, Container, TextareaAutosize, TextField } from '@mui/material';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import IProduct from '../../models/product/IProduct';
import ImageUploader from 'react-images-upload';
import ICategory from '../../models/category/ICategory';
import { Autocomplete } from '@mui/lab';
import * as ProductAction from '../../store/actions/Product.Action';
import * as CommonAction from '../../store/actions/Common.Action';

const classNames = require('classnames');

interface IPageProps {
    classes?: any,
    history: any;
    match: any;
    user: any;
    editProductDetails?: IProduct
    categoryOptions: ICategory[]
    addProduct: (product: string) => any;
    getProductById: (productId: number) => any;
    resetEditProductDetails: () => void;
    getAllCategory: () => void;
    updateProduct: (productId: number, ProductBody: string) => any;
    postProductImageSave: (imageDto: any, multipleFile: FormData) => any;
    getSensorsList: () => void;
    getStmMcuFamilyList: () => void;
    sensorsList: any[],
    stmMcuFamilyList: any[],
}
interface IPageState extends IProduct {
    title?: string
    uploadImages?: any[],
    categoryId?: number,
    version?: number,
    versionDate?: Date,
}
class ProductForm extends React.Component<RouteComponentProps & IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();

    constructor(props: any) {
        super(props);
        this.state = {
            uploadImages: []
        }
    }

    public componentWillMount = async () => {
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this, messages: {
                integer: "Please enter only number"
            }
        });
        this.props.getAllCategory();
        this.props.getSensorsList();
        this.props.getStmMcuFamilyList();
        const productId = this.props.match.params.id;
        const pathname = this.props.match.path;
        if (pathname === "/unwired-product/edit/:id") {
            if (productId) {
                await this.props.getProductById(productId).then((response: any) => {
                    console.log('')
                }).catch((error: any) => {
                    toast("Product Not found")
                    this.props.history.push('/unwired-product');
                    this.props.resetEditProductDetails();
                });
            }
        }

    }
    public componentWillUnmount() {
        this.props.resetEditProductDetails();
    }
    // public componentDidMount() {
    //     const productId = this.props.match.params.id;
    //     const pathname = this.props.match.path;
    //     if (pathname === "/unwired-product/edit/:id") {
    //         const editProduct = productDetails.find(x => x.id === parseInt(productId));
    //         if (editProduct) {
    //             this.setState({ ...editProduct, uploadImages: [...editProduct.images] })
    //         } else {
    //             toast("product not found", { type: "error" })
    //         }
    //     }
    // }
    public componentDidUpdate(prevProps: IPageProps, prevState: IPageState) {
        if (prevProps?.editProductDetails?.productId !== prevState.productId) {
            const { productId, name, priority, description, images, maxPerOrderQuantity, quantity, category, price, stock, sensorsId, stmMcuFamily,
                extraNotes, cloneUrl,
                serviceFee,
                youReceive } = prevProps.editProductDetails || {};
            if (productId) {
                this.setState({
                    productId, name, sensorsId, serviceFee,
                    extraNotes, stm32McuFamilyId: stmMcuFamily?.stm32McuFamilyId, cloneUrl,
                    youReceive, priority, description,
                    images, maxPerOrderQuantity, quantity,
                    price, stock, categoryId: category?.categoryId,
                    category, stmMcuFamily
                });
            }
        }


    }
    private onHandleChangeForm = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            ...this.state,
            [name]: value
        }, () => {
            if (name === 'price' || name === 'serviceFee') {
                if (this.state.serviceFee && this.state.price) {
                    this.setState({ youReceive: this.state.price - this.state.serviceFee })
                }
            }
        });



    }

    private handleSubmitDetails = async () => {
        if (this.validator.allValid()) {
            const copyProduct: any = this.state;
            const payload: any = {
                ...copyProduct,
                "category": {
                    "categoryId": this.state.categoryId
                },
                "stmMcuFamily": {
                    "stm32McuFamilyId": this.state.stm32McuFamilyId,
                },
                userId: this.props.user.userId,
            };
            if (typeof payload.price === 'string') {
                payload.price = payload.price.replace('$', '');
            }
            delete payload.categoryId;
            delete payload.uploadImages
            // const arrayImages: any[] = [...copyProduct.uploadImages];
            // const payloadFormData: FormData = new FormData();
            // arrayImages.forEach((eachFile) => {
            //     payloadFormData.append("uploadImages", eachFile);
            // })
            if (!this.state.productId) {
                await this.props.addProduct(JSON.stringify(payload)).then(async (response: any) => {
                    if (response && response.status?.success === 'SUCCESS') {
                        this.props.history.push('/instruction')
                    }
                    // if (response.data) {
                    //     this.uploadImage(response.data.productId,payloadFormData)
                    // }
                }).catch((error: any) => {
                    console.error(error);
                });
            } else {
                this.props.updateProduct(this.state.productId, JSON.stringify(payload)).then((response: any) => {
                    // this.uploadImage(this.state.productId,payloadFormData)
                    if (response && response.status?.success === 'SUCCESS') {
                        this.props.history.push('/instruction')

                       // this.props.history.push('/unwired-product')
                    }
                }).catch((error: any) => {
                    console.error(error);
                });
            }
        } else {
            this.validator.showMessages();
        }
    }

    // private uploadImage = async(productId:any,payloadFormData:FormData)=> {
    //     const urlParam = `%7B%20%20%20%22productId%22%3A${productId}%2C%20%20%20%22version%22%3A${this.state.version}%2C%20%20%20%22versionDate%22%3A%22${this.state.versionDate}%22%20%7D`;
    //     await this.props.postProductImageSave(urlParam, payloadFormData).then((response: any) => {
    //         this.props.history.push('/unwired-product')
    //     })
    // }
    private handleCancel = () => {
        this.props.history.push('/unwired-product');
    }
    private onDrop = (uploadImages: any) => {
        this.setState({ uploadImages });
        // this.setState({
        //     uploadImages: this.state.uploadImages.concat(picture),
        // });
    }
    public render() {
        this.validator.purgeFields();
        const { classes, categoryOptions, sensorsList, stmMcuFamilyList } = this.props;
        const { name, price,
            priority, description, categoryId,
            sensorsId, stmMcuFamily,
            stm32McuFamilyId,
            productId,
            extraNotes,
            serviceFee,
            cloneUrl,
            category,
            youReceive } = this.state;
        const sensorObject = { "sensorsId": sensorsId }
        return (
            <Paper className={classNames(classes.paper)} elevation={5} square={false}>
                <Typography component="p" align='center' className={classes.sectionTitle}>Product Details</Typography>
                <Grid container={true} spacing={4}>
                    <Container maxWidth="lg">
                        <form>
                            <Grid item={true} xs={12} sm={12}>
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="name">Product Name</InputLabel>
                                            <Input value={name || ''} onChange={this.onHandleChangeForm} id="name" name="name"
                                                onBlur={() => this.validator.showMessageFor('name')}
                                            />
                                            {this.validator.message('name', name, 'required')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <Autocomplete
                                                freeSolo={true}
                                                id="categoryId"
                                                value={category || ''}
                                                disableClearable={true}
                                                onChange={(event: any, newValue: any) => {
                                                    if (newValue) {
                                                        this.setState({ category: newValue, categoryId: newValue.categoryId })
                                                    }
                                                }}
                                                isOptionEqualToValue={(option, value) => option.categoryId === value.categoryId}
                                                getOptionLabel={(option: any) => option?.name || ''}
                                                options={Array.isArray(categoryOptions) ? categoryOptions : []}
                                                renderInput={(params) => (
                                                    <TextField required={true}
                                                        {...params}
                                                        label="Sensor Family"
                                                        margin="none"
                                                        variant="standard"
                                                        id="categoryId"
                                                        name="categoryId"
                                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                                    />
                                                )}
                                            />
                                            {this.validator.message('category', categoryId, 'required')}

                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <Autocomplete
                                                freeSolo={true}
                                                id="sensorsId"
                                                disabled={!categoryId}
                                                disableClearable={true}
                                                defaultValue={sensorsId}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        this.setState({ sensorsId: newValue.sensorsId })
                                                    }
                                                }}
                                                isOptionEqualToValue={(option, value) => {
                                                    return option.sensorsId === value.sensorsId
                                                }}
                                                getOptionLabel={(option: any) => option?.mfrPart || ''}
                                                options={Array.isArray(sensorsList) ? sensorsList : []}
                                                renderInput={(params) => (
                                                    <TextField required={true}
                                                        {...params}
                                                        label="Sensor Manufacturer ID"
                                                        margin="none"
                                                        variant="standard"
                                                        id="sensorsId"
                                                        name="sensorsId"
                                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                                    />
                                                )}
                                            />
                                            {this.validator.message('sensorsId', sensorsId, 'required')}

                                        </FormControl>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <Autocomplete
                                                style={{ fontWeight: 'bold' }}
                                                freeSolo={true}
                                                id="stm32McuFamilyId"
                                                disabled={!categoryId && !sensorsId}
                                                value={stmMcuFamily || ''}
                                                disableClearable={true}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        this.setState({ stmMcuFamily: newValue })
                                                        this.setState({ stm32McuFamilyId: newValue.stm32McuFamilyId })
                                                    }
                                                }}
                                                getOptionLabel={(option: any) => option?.partNo || ''}
                                                options={Array.isArray(stmMcuFamilyList) ? stmMcuFamilyList : []}
                                                renderInput={(params) => (
                                                    <TextField required={true}
                                                        {...params}
                                                        label="MCU"
                                                        margin="none"
                                                        variant="standard"
                                                        id="stm32McuFamilyId"
                                                        name="stm32McuFamilyId"
                                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                                    />
                                                )}
                                            />
                                            {this.validator.message('stm32McuFamilyId', stm32McuFamilyId, 'required')}

                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="priority">Priority</InputLabel>
                                            <Input value={priority || ''} onChange={this.onHandleChangeForm} id="priority" name="priority"
                                                onBlur={() => this.validator.showMessageFor('priority')}
                                            />
                                            {this.validator.message('priority', priority, 'required|integer')}
                                        </FormControl>
                                    </Grid>
                                    {(productId && cloneUrl) &&
                                        <Grid item={true} xs={12} sm={6}>
                                            <FormControl fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="cloneUrl">Clone Url</InputLabel>
                                                <Input value={cloneUrl || ''} id="cloneUrl" name="cloneUrl" disabled={true}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }

                                    <Grid item={true} xs={12} sm={12}>
                                        <Typography style={{ border: '2px solid #ccc' }}>
                                            <Container style={{ marginBottom: 25, marginTop: 25 }}>
                                                <Grid container={true} spacing={4}>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                                            <InputLabel htmlFor="price">Price</InputLabel>
                                                            <Input value={price || ''} onChange={this.onHandleChangeForm} id="price" name="price"
                                                                onBlur={() => this.validator.showMessageFor('price')}
                                                            />
                                                            {this.validator.message('price', price, 'required|currency')}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                                            <InputLabel htmlFor="serviceFee">Service Fee</InputLabel>
                                                            <Input value={serviceFee || ''} onChange={this.onHandleChangeForm} id="serviceFee" name="serviceFee"
                                                                onBlur={() => this.validator.showMessageFor('serviceFee')}
                                                            />
                                                            {this.validator.message('serviceFee', serviceFee, 'required|currency')}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                                            <InputLabel htmlFor="price">You Receive</InputLabel>
                                                            <Input value={youReceive || ''} disabled={true} onChange={this.onHandleChangeForm} id="youReceive" name="youReceive"
                                                                onBlur={() => this.validator.showMessageFor('youReceive')}
                                                            />
                                                            {this.validator.message('youReceive', youReceive, 'required|currency')}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </Typography>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <InputLabel style={{ marginBottom: '8px' }} htmlFor="description">Designer notes for the users of this code</InputLabel>
                                        <TextareaAutosize
                                            className={classes.textareaBoxSize}
                                            id="description"
                                            name="description"
                                            onChange={this.onHandleChangeForm}
                                            onBlur={() => this.validator.showMessageFor('description')}
                                            style={{ height: '74px' }}
                                            placeholder=""
                                            value={description || ''}
                                            autoComplete="description"
                                        />
                                        {this.validator.message('description', description, 'required')}
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <InputLabel style={{ marginBottom: '8px' }} htmlFor="extraNotes">Extra notes</InputLabel>
                                        <TextareaAutosize
                                            className={classes.textareaBoxSize}
                                            id="extraNotes"
                                            name="extraNotes"
                                            onChange={this.onHandleChangeForm}
                                            onBlur={() => this.validator.showMessageFor('extraNotes')}
                                            style={{ height: '74px' }}
                                            placeholder=""
                                            value={extraNotes || ''}
                                            autoComplete="extraNotes"
                                        />
                                        {this.validator.message('extraNotes', extraNotes, 'required')}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12} sm={12} style={{ paddingTop: 20 }}>
                                <Grid container={true} spacing={3} className={classes.alignButton}
                                    style={{
                                        flexDirection: 'row-reverse', marginTop: 5, marginBottom: 10,
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                                    }}>
                                    <Grid item={true} xs={12} sm={6} >
                                        <Button
                                            onClick={this.handleSubmitDetails}
                                            variant="text"
                                            color="primary"
                                            style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' }}
                                            className={classes.buttonCommon}
                                        >Save</Button>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}  >
                                        <Button
                                            onClick={this.handleCancel}
                                            variant="text"
                                            color="secondary"
                                            style={{ backgroundColor: '#f8bbd0', textAlign: "center", color: '#6d6c6c' }}
                                            className={classes.buttonCommon}
                                        >Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>
                </Grid>
            </Paper>)
    }
}
const styles = (theme: Theme) => ({
    paper: {
        padding: theme.spacing(3),
        color: theme.palette.text.secondary,
        marginBottom: '6%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    formTitle: {
        fontWeight: 'bold'
    },
    buttonCommon: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
        [theme.breakpoints.between('md', 'xl')]: {
            width: '50%'
        },
    },
    alignButton: {
        marginBottom: '4px',
        [theme.breakpoints.between('md', 'xl')]: {
            flexDirection: 'row-reverse',
            textAlign: 'center',
        },
    },
    sectionTitle: {
        marginBottom: "32px !important"
    },
    textareaBoxSize: {
        minWidth: '100%',
        maxWidth: '100%',
        border: '2px solid #cccc'
    },

});
const mapStateToProps = (state: any) => {
    return {
        categoryOptions: state.category?.adminCategoryList,
        editProductDetails: state.product?.editProductDetails,
        sensorsList: state.app?.sensorsList,
        stmMcuFamilyList: state.app?.stmMcuFamilyList,
        user: state.auth.user
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        addProduct: ProductAction.postSaveProduct,
        postProductImageSave: ProductAction.postSaveProductImages,
        resetEditProductDetails: ProductAction.resetEditProductDetails,
        getProductById: ProductAction.getProductById,
        updateProduct: ProductAction.putUpdateProduct,
        getAllCategory: CommonAction.getAllCategory,
        getSensorsList: CommonAction.getSensorsList,
        getStmMcuFamilyList: CommonAction.getStmMcuFamilyList,
    }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(ProductForm as any)) as any);
