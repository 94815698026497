import { Button, Menu, MenuItem,Theme} from "@mui/material"
import React from "react"
import IFilterModel from "../../models/common/IFilterModel";
import { withStyles, createStyles } from '@mui/styles';

const styles = (theme: Theme) => createStyles({
    grow: {
        flexGrow: 1,
        alignItems: 'baseline'
    },
});
export const ExportDataMenus = withStyles(styles)((props: any) => {

    const { exportAnchorEl, handleCloseExportMenu, handleClickExport, exportMenuOptions, selectedExportMenuIndex, handleDownloadMenuItemClick } = props;
    return (<>
        {(handleClickExport && exportMenuOptions) && <>
            <Button style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c', marginRight: 10  }} onClick={(event) => handleClickExport(event)}>Export</Button>
            <Menu
                id="show-filter-menu"
                anchorEl={exportAnchorEl}
                keepMounted={true}
                open={Boolean(exportAnchorEl)}
                onClose={handleCloseExportMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                // elevation={0}
               // getContentAnchorEl={null}
            >
                {exportMenuOptions && exportMenuOptions.map((option: IFilterModel, index: number) => (
                    <MenuItem
                        key={option.key}
                        selected={index === selectedExportMenuIndex}
                        onClick={(event:any) => handleDownloadMenuItemClick(event, index, option)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
            </>}
    </>
    )
});