import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import SignIn from './SignIn';
const LoginButton = () => {
    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
    } = useAuth0();
    return !isAuthenticated && (<SignIn/>);
}

export default LoginButton;