import { ActionType, IAppAction } from '../../actions/Helpers';

const initialState = {
    order: null,
    orderHistoryList: [] as any,
    serviceCharge: Number(0),
}
export const orderReducer = (state = initialState, action = {} as IAppAction) => {
    switch (action.type) {
        case ActionType.GET_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                order: action.payload,
            }
        case ActionType.GET_ORDERS_HISTORY_SUCCESS:
            return {
                ...state,
                orderHistoryList: action.payload,
            }
        case ActionType.GET_ORDERS_HISTORY_FAILURE:
            return {
                ...state,
                orderHistoryList: [],
            }
        case ActionType.GET_ORDER_DETAILS_FAILURE:
            return {
                ...state, order: null,
            }

        default:
            return state
    }
}
