import { ActionType, IAppAction } from '../store/actions/Helpers';
import * as Actions from '../store/actions/Product.Action';
import * as AppActions from '../store/actions/App.Action'
import axios from 'axios';
import { PORTAL_API_URL, getHeader } from './Base.Service';
import { APIConstantList } from '../AppConstants';
const ProductApiMiddleware = (propsApiMid: any) => (next: any) => async (action: IAppAction) => {
    const { dispatch, getState } = propsApiMid;
    const headers = await getHeader();
    switch (action.type) {
        case ActionType.GET_PRODUCTS_LIST_REQUEST:
            next(AppActions.showLoader())
            axios.get(`${PORTAL_API_URL + APIConstantList.getProductsByRange}start=0&limit=40`, {
                headers: headers,
            }).then((response) => {
                next(Actions.fetchProductsSuccess(response.data));
                next(AppActions.hideLoader())
            }).catch((error) => {
                next(Actions.fetchProductsFailure());
                next(AppActions.hideLoader())
            });
            break;
        case ActionType.GET_PRODUCT_BY_ID_REQUEST:
            next(AppActions.showLoader())
            axios.get(`${PORTAL_API_URL + APIConstantList.getProductById + action.payload}`,
                { headers: headers }).then((response: any) => {

                    next(Actions.fetchProductByIdSuccess(response.data.data));
                    next(AppActions.hideLoader())
                }).catch((error) => {
                    next(Actions.fetchProductByIdFailure());
                    next(AppActions.hideLoader())
                });
            break;
        case ActionType.GET_PRODUCT_BY_CATEGORY_ID_REQUEST:
            next(AppActions.showLoader())
            axios.get(`${PORTAL_API_URL + APIConstantList.getProductListByCategoryId + action.payload}`,
                { headers: headers }).then((response: any) => {
                    next(Actions.fetchProductsListByCategoryIdSuccess(response.data.data));
                    next(AppActions.hideLoader())
                }).catch((error) => {
                    next(Actions.fetchProductsListByCategoryIdFailure(error));
                    next(AppActions.hideLoader())
                });
            break;
        default:
            break;
    }

    await next(action);
};

export default ProductApiMiddleware;
