export const RouteAccess = {
  Administrator: 'AU',
  AdminAndContributor: ['AU','C'],
  User: 'U',
  Contributor:'C',
  All: ['U','AU','C']
};
export const APIConstantList = {

  getProductById:'products/product/',
  getProductsByRange:'products/range?',
  postSaveProduct:'products/product/',
  postSaveProductImages:'product/images/',
  putUpdateProductById:'products/product/',
  getProductListByCategoryId: 'categorys/category/',
  
  postCreateCart:'cart/create/',
  putUpdateCart:'cart/',
  deleteCartItem:'cart/item/',
  getCartDetails:'cart/details',

  getCategoryByRange: 'categorys/range?',
  postSaveCategory:'categorys/category/',
  putUpdateCategoryById:'categorys/category/',
  getCategoryById:'categorys/category/',

 
  getOrdersByRange:'orders/range',
  postSaveORUpdateOrders:'orders/order/',
}

export const TokenStoreKey = 'auth-token';
