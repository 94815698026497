import { ActionType, IAppAction } from '../../actions/Helpers';

const initialState = {
    shoppingCart: null,
    serviceCharge: Number(0),
    taxTotal: Number(0),
    total: Number(0),
    subTotal: Number(0),
    cartCount: Number(0),
}
export const cartReducer = (state = initialState, action = {} as IAppAction) => {
    switch (action.type) {
        case ActionType.GET_CART_DETAILS_SUCCESS:
            const shoppingCartDetails: any = action.payload;
            let cartCount: number = 0;
            let subTotal: number = 0;
            let total: number = 0;
            if (shoppingCartDetails && shoppingCartDetails.items) {
                total = shoppingCartDetails?.items?.reduce((count: number, curItem: any) => {
                    return count + (curItem.price * curItem.quantity);
                }, 0)
                cartCount = shoppingCartDetails?.items?.reduce((count: number, curItem: any) => {
                    cartCount += curItem.quantity
                    return cartCount;
                }, 0)
                subTotal = total;
            }
            return { ...state, 
                shoppingCart: action.payload,
                subTotal: subTotal,
                total: total,
                cartCount: cartCount,
            }
        case ActionType.GET_CART_DETAILS_FAILURE:
            return { ...state, shoppingCart: null }
        case ActionType.ADD_TO_CART:
            return { ...state }
        case ActionType.CLOSE_SPINNER:
            return { ...state, isAppLoader: false }
        default:
            return state
    }
}
